/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-zn7x9a --style2 js-anim  --anim3 --full" name={"kontakt"} fullscreen={true} parallax={false} anim={"3"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/573da4b0c9ab45278d71cfed769e9624_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/573da4b0c9ab45278d71cfed769e9624_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/573da4b0c9ab45278d71cfed769e9624_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/573da4b0c9ab45278d71cfed769e9624_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/573da4b0c9ab45278d71cfed769e9624_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/573da4b0c9ab45278d71cfed769e9624_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/573da4b0c9ab45278d71cfed769e9624_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/573da4b0c9ab45278d71cfed769e9624_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --center pl--0" anim={"6"} animS={"5"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62 w--500 lh--1" content={"<span style=\"color: white;\">Servis plastových oken a eurooken&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ufrnt6s1i6a"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--48" content={"Základní nacenění bezplatně při první kontrole Vašich oken a dveří"}>
              </Title>

              <Text className="text-box fs--36" content={"Profesionální servis plastových oken.<br>Máte problémy s plastovými okny?\n<br><span style=\"font-weight: bold;\">Naše odborná servisní služba je tu pro vás!<br></span><br>&nbsp; Nabízíme komplexní servis plastových oken, který zahrnuje:\n<br>Diagnostiku a opravy:<br>Rychle a efektivně identifikujeme a opravíme jakýkoli problém s vašimi okny.\n<br>&nbsp;Zajistíme, aby vaše okna fungovala hladce a efektivně, čímž zvýšíme jejich životnost a energetickou účinnost.\n<br>Zajistíme výměnu těsnění v případě nutnosti a tím zamezíme průvanu a úniku tepla, což vám ušetří náklady na vytápění.\n<br>&nbsp;Poskytneme vám odborné rady a doporučení pro optimální péči o vaše okna.\n<br>&nbsp;<span style=\"font-weight: bold;\">Proč si vybrat nás?\n</span><br>&nbsp;Rychlá reakce: <br>Na vaše požadavky reagujeme rychle a flexibilně.\n\n Kvalifikovaní technici:\n<br>Naši technici jsou vyškolení profesionálové s dlouholetou praxí.\n<br>Používáme pouze kvalitní náhradní díly a materiály.\n<br>Naším cílem je vaše spokojenost a pohodlí.\n\n<span style=\"font-weight: bold;\">Kontaktujte nás ještě dnes a zajistěte si bezproblémový provoz vašich plastových oken!</span>"}>
              </Text>

              <Button className="btn-box" content={"Kontaktní formulář"} use={"page"} href={"/kontakt-1"} target={null}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"cenik"} style={{"backgroundColor":"rgba(135,199,245,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--10 pt--10" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box fs--48" style={{"maxWidth":""}} content={"<span style=\"font-style: italic;\">Mám zájem o profesionální servis plastových oken a chci bezplatnou konzultaci</span>"}>
              </Title>

              <Text className="text-box text-box--center fs--43" style={{"maxWidth":""}} content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Button className="btn-box" content={"Kontaktní formulář"} use={"page"} href={"/kontakt-1"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-q7l7q4 --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/67b414d9daaa4c73a60d09ddddc44cec_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/67b414d9daaa4c73a60d09ddddc44cec_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/67b414d9daaa4c73a60d09ddddc44cec_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/67b414d9daaa4c73a60d09ddddc44cec_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/67b414d9daaa4c73a60d09ddddc44cec_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/67b414d9daaa4c73a60d09ddddc44cec_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/67b414d9daaa4c73a60d09ddddc44cec_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/67b414d9daaa4c73a60d09ddddc44cec_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--20" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1--95);\">+420 494 909 023\n<br>fit-okna@seznam.cz</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"}>
        </Column>

      </Layout>
    </ThemeWrapper>
  )
}